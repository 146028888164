.chip {
  max-width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Inter, Epilogue, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.6674107142857143rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  white-space: nowrap;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;

  font-weight: 400;
  font-size: 14px;
  padding: 0px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
  }

  &.win {
    color: #00db97;
    background-color: #00db4a08;
  }

  &.lose {
    color: #FE025C;
    background-color: #FE025C08;
  }

  &.refunded {
    color: #f44336;
    background-color: #f4433608;
  }

  &.pending {
    color: #f4b136;
    background-color: #f4433608;
  }

  &.bear {
    color: #00db97;
    background-color: #00db4a08;
  }

  &.h3 {
    font-size: 1.125rem
  }
}
