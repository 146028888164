/* Tables */

.table {
  margin-bottom: 0;
  color: $text-muted;
  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: $font-weight-medium;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
  
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
}

.table-wrapper {
  position: relative;
}
.table-scroll {
  height: 80em;
  overflow: auto;
  margin-top: 20px;
}
.table-wrapper table {
  width: 100%;
}

.table-text-center {
  text-align: center;
}